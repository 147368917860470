import React, { useState, useEffect } from "react";
import "./App.css";
import { motion } from "framer-motion";
import { DayAndNightToggle } from "./DayAndNightToggle";
import pako from "pako";
import Papa from "papaparse";
import Dialog from "rc-dialog";
import Drawer from "react-modern-drawer";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  PinterestShareButton,
  PinterestIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import "rc-dialog/assets/index.css";

const colors = ["#ff0000", "#800080", "#16279f"];

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [buttonText, setButtonText] = useState("GENERATE");
  const [serverDetailsList, setServerDetailsList] = useState([]);
  const [stepsText, setStepsText] = useState("STEP 1");
  const [serverText, setServerText] = useState("");
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [showPassword, setShowPassword] = useState(false);
  const [currentServer, setCurrentServer] = useState(null);
  const [currentServerInfo, setCurrentServerInfo] = useState(null);

  const [toggleCurrentServerInfo, setToggleCurrentServerInfo] = useState(false);
  const [toggleCopyShareContainer, setToggleCopyShareContainer] =
    useState(false);
  const [toggleShareDrawer, setToggleShareDrawer] = useState(false);

  const fetchGzipFile = async () => {
    setIsLoading(true);
    setButtonText("WAITING");
    
    const url =
      "https://firebasestorage.googleapis.com/v0/b/xtream-iptv-code-generator.appspot.com/o/servers.csv.gz?alt=media";

    try {
      const response = await fetch(url);
      if (!response.ok) {
        alert(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();

      // Decompress the gzipped file
      const decompressed = pako.inflate(new Uint8Array(arrayBuffer), {
        to: "string",
      });

         // Use a Promise to wrap Papa.parse
         const results = await new Promise((resolve, reject) => {
          Papa.parse(decompressed, {
              header: false,
              complete: (parsedResults) => {
                  resolve(parsedResults);
              },
              error: (err) => {
                  reject(new Error(`Failed to parse CSV: ${err.message}`));
              },
          });
      });

      const formattedData = results.data.map((item) => ({
          url: item[0],       // The URL (e.g., "http://iptvturkpro.com:8080")
          username: item[1],  // The username (e.g., "9Jrerx2kFk")
          password: item[2],  // The password (e.g., "CqTUabBAD")
      }));

      setServerDetailsList(formattedData);
    
      setButtonText(results.data.length > 0 ? "GENERATE" : "RETRY");
   

    } catch (exception) {
      setError(`Failed to download file: ${exception.message}`);
      setButtonText("RETRY");
    }
    setIsLoading(false);
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("Copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  let isCalled = false;
  useEffect(() => {
    if (!isCalled) {
      fetchGzipFile();
      isCalled = true;
    }
  }, []);

  const onButtonSubmit = async () => {
    setIsLoading(true);
    setError(null);
    if (buttonText == "GENERATE" || buttonText == "GENERATE ANOTHER") {
      setShowPassword(false);
      setToggleCopyShareContainer(false);

      setButtonText("GENERATING");
      await sleep(3000);

      const randomIndex = Math.floor(Math.random() * serverDetailsList.length);

      let server_ = serverDetailsList[randomIndex];

      setCurrentServer(server_);

      setServerText(server_.url);
      setUsernameText(server_.username);
      setPasswordText(server_.password);

      setStepsText("STEP 2");
      setButtonText("CHECK STATUS");
    } else if (buttonText == "CHECK STATUS") {
      setButtonText("CHECKING (10)");
      await sleep(1000);
      setButtonText("CHECKING (9)");
      await sleep(1000);
      setButtonText("CHECKING (8)");
      await sleep(1000);
      setButtonText("CHECKING (7)");
      await sleep(1000);
      setButtonText("CHECKING (6)");
      await sleep(1000);
      setButtonText("CHECKING (5)");
      await sleep(1000);
      setButtonText("CHECKING (4)");
      await sleep(1000);
      setButtonText("CHECKING (3)");
      await sleep(1000);
      setButtonText("CHECKING (2)");
      await sleep(1000);
      setButtonText("CHECKING (1)");


      const proxy = "https://xtream.couponzdiscount.com/api.php?url=";
      const url = proxy + encodeURIComponent(`${currentServer.url}/player_api.php?username=${currentServer.username}&password=${currentServer.password}&type=m3u_plus&output=ts`);

      try {
        const response = await fetch(url);

        if (!response.ok) {
          setError("UNABLE TO SHOW SERVER DETAILS");
          setStepsText("STEP 3");
          setButtonText("VIEW PASSWORD");
        }

        const data = await response.json();

        const userInfo = data.user_info;
        const serverInfo = data.server_info;

        const createdAtDate = new Date(userInfo.created_at * 1000);
        const expDate = new Date(userInfo.exp_date * 1000);

        const formattedCreatedAt = createdAtDate.toLocaleString();
        const formattedExpDate = expDate.toLocaleString();

        let serverInfo_ = {
          username: userInfo.username,
          password: userInfo.password,
          status: userInfo.status,
          isTrial: userInfo.is_trial === "0" ? "No" : "Yes",
          createdAt: formattedCreatedAt,
          expDate: formattedExpDate,
          url: serverInfo.url,
          port: serverInfo.port,
          timezone: serverInfo.timezone,
          timeNow: serverInfo.time_now,
          maxConnections: userInfo.max_connections,
        };
        setCurrentServerInfo(serverInfo_);
        setToggleCurrentServerInfo(true);
        setStepsText("STEP 3");
        setButtonText("VIEW PASSWORD");
      } catch (error) {
        

        setError("THIS SERVER IS NOT WORKING. GENERATE ANOTHER");
        setStepsText("STEP 1");
        setButtonText("GENERATE");
      }
    } else if (buttonText == "VIEW PASSWORD") {
      setButtonText("LOADING");
      await sleep(3000);
      setShowPassword(true);
      setButtonText("GENERATE ANOTHER");
      setToggleCopyShareContainer(true);
    } else if (buttonText == "RETRY") {
      await fetchGzipFile();
    }
    setIsLoading(false);
  };

  return [24, 25, 26, 27].includes(new Date().getDate()) && (
    <>
      <div>
        <div className="gradient-container">
          <div className="starry-sky">
            <h1 className="top-heading">XTREAM IPTV CODE GENERATOR</h1>
          </div>
        </div>

        <div className={isDarkMode ? "app dark-mode" : "app"}>
          <div className="container">
            <div className="day-night-switcher">
              <DayAndNightToggle
                onChange={() => setIsDarkMode(!isDarkMode)}
                checked={isDarkMode}
              />
            </div>
            <motion.div
              initial={{ color: colors[0] }}
              animate={{
                color: colors, // Cycle through colors
              }}
              transition={{
                duration: 1, // Total duration for one full cycle
                ease: "linear",
                repeat: Infinity,
              }}
              style={{ fontSize: "14px", fontWeight: 700 }}
            >
              GET YOUR SERVER IN 3 STEPS
            </motion.div>

            <div className="input-container">
              <input
                type="text"
                value={serverText}
                readOnly
                autocomplete="off"
                placeholder="Server"
              />
              <input
                type="text"
                value={usernameText}
                readOnly
                placeholder="Username"
                autocomplete="new-password"
                name={`username${Math.floor(Math.random() * 1000) + 1}`}
              />
              <input
                type={showPassword ? "text" : "password"}
                value={passwordText}
                readOnly
                placeholder="Password"
                autocomplete="new-password"
                name={`password${Math.floor(Math.random() * 1000) + 1}`}
              />
            </div>
            <motion.div
              initial={{ color: colors[0] }}
              animate={{
                color: colors, // Cycle through colors
              }}
              transition={{
                duration: 1, // Total duration for one full cycle
                ease: "linear",
                repeat: Infinity,
              }}
              style={{ fontSize: "14px", fontWeight: 700, marginBottom: 10 }}
            >
              {stepsText}
            </motion.div>
            <span className="error-text">{error}</span>
            <div className="stretch-container">
              <button
                disabled={isLoading}
                className="generate-btn"
                onClick={onButtonSubmit}
              >
                {buttonText}
              </button>
              {toggleCopyShareContainer && (
                <div className="copy-and-share-container">
                  <button
                    onClick={() => {
                      copyToClipboard(
                        serverText + "\n" + usernameText + "\n" + passwordText
                      );
                    }}
                    className="copy-and-share-button copy-and-share-button-left"
                  >
                    COPY
                  </button>
                  <button
                    onClick={() => {
                      setToggleShareDrawer(true);
                    }}
                    className="copy-and-share-button copy-and-share-button-right"
                  >
                    SHARE
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {currentServerInfo && (
        <Dialog
          className={isDarkMode ? "dialog-dark-mode" : ""}
          title={"Server Details"}
          onClose={() => {
            setToggleCurrentServerInfo(false);
          }}
          visible={toggleCurrentServerInfo}
        >
          <motion.div
            initial={{ color: colors[0] }}
            animate={{
              color: colors, // Cycle through colors
            }}
            transition={{
              duration: 1, // Total duration for one full cycle
              ease: "linear",
              repeat: Infinity,
            }}
            style={{
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "center",
              paddingBottom: 10,
            }}
          >
            CONGRATULATIONS! THE SERVER IS WORKING
          </motion.div>
          <div className={"dialog-server-info-container"}>
            <span className="dialog-server-info-container-span">
              {currentServerInfo.url}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {currentServerInfo.port}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {currentServerInfo.username}
            </span>
            <hr />
            <span className="dialog-server-info-container-span">
              {"*".repeat(currentServerInfo.password.length)}
            </span>
          </div>
          <div className={"dialog-server-info-more-details"}>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Status
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.status}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Created At
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.createdAt}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Expiration Date
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.expDate}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Timezone
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.timezone}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Time Now
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.timeNow}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Max Connections
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.maxConnections}
              </span>
            </div>
            <div className={"dialog-server-info-more-details-container"}>
              <span
                className={"dialog-server-info-more-details-container-heading"}
              >
                Is Trial
              </span>
              <span
                className={"dialog-server-info-more-details-container-content"}
              >
                {currentServerInfo.isTrial}
              </span>
            </div>
          </div>
          <motion.div
            initial={{ color: colors[0] }}
            animate={{
              color: colors, // Cycle through colors
            }}
            transition={{
              duration: 1, // Total duration for one full cycle
              ease: "linear",
              repeat: Infinity,
            }}
            style={{ fontSize: "14px", fontWeight: 700, textAlign: "center" }}
          >
            PRESS BACK BUTTON TO VIEW SERVER
          </motion.div>

          <div
            className="dialog-server-info-more-details-back-btn"
            onClick={() => {
              setToggleCurrentServerInfo(false);
            }}
          >
            BACK
          </div>
        </Dialog>
      )}

      <Drawer
        open={toggleShareDrawer}
        onClose={() => {
          setToggleShareDrawer(false);
        }}
        direction="bottom"
        className={isDarkMode ? "share-drawer dark-share-drawer" : "share-drawer"}
      >
        <div
          className="drawer-share-close-btn"
          onClick={() => {
            setToggleShareDrawer(false);
          }}
        >
          BACK
        </div>
        <h1 className="share-icons-title">SHARE</h1>
        <div className="share-icons-container">
         
          <div className="share-icons">
            <EmailShareButton
              subject="Sever Details"
              body={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className="share-icons">
            <FacebookShareButton
               url={String(window.location)}
            
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className="share-icons">
            <WhatsappShareButton
                url={""}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="share-icons">
            <TelegramShareButton
               url={String(window.location)}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          <div className="share-icons">
            <TwitterShareButton
            url={String(window.location)}
              title={serverText + "\n" + usernameText + "\n" + passwordText}
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="share-icons">
            <InstapaperShareButton
              url={String(window.location)}
              title="Sever Details"
              description={
                serverText + "\n" + usernameText + "\n" + passwordText
              }
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </div>
          <div className="share-icons">
            <PinterestShareButton
              url={serverText + "\n" + usernameText + "\n" + passwordText}
              media={`${String(window.location)}/loadingscreen.png`}
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default App;
